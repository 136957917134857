import Vue from "vue";
import App from "./App.vue";

Vue.config.productionTip = false;

fetch("https://api.merge.dev/api/integrations/create-link-token", {
  method: "POST",
  headers: {
    Authorization: "Bearer pHwM-3qovX6n1qcC_Mv8FUtkIHYyhfkiJcH5o79IV1j8mkknDd1xxA",
    "Content-Type": "application/json",
  },
  body: JSON.stringify({
    end_user_email_address: "Test Email",
    end_user_organization_name: "Test Name",
    end_user_origin_id: "404",
    categories: ["hris"],
  }),
})
  .then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw response;
  })
  .then((data) => {
    console.log({ linkToken: data.link_token });
    new Vue({
      data: { linkToken: data.link_token },
      render: (h) => h(App),
    }).$mount("#app");
  });
