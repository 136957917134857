<template>
  <section>
    <merge-link :linkToken="this.$root.linkToken" v-bind="{ onSuccess }">
      <template slot="button" slot-scope="props">
        <button @click="props.onClick">Open Merge Link</button>
      </template>
    </merge-link>
  </section>
</template>

<script>
import MergeLink from "@mergeapi/vue-merge-link";

export default {
  name: "App",
  components: { MergeLink },
  methods: {
    onSuccess(token) {
      fetch(`https://api.merge.dev/api/integrations/account-token/${token}`, {
        method: "GET",
        headers: {
          Authorization: "Bearer pHwM-3qovX6n1qcC_Mv8FUtkIHYyhfkiJcH5o79IV1j8mkknDd1xxA",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          console.log(data);
        });
    },
  },
};
</script>
